import React, { Fragment } from "react";
import "./work.scss";
import {LoaderContext} from "../../commons/loader/loader";
import {projectsUrl} from "../../commons/constants";
import axios from 'axios';
import {Link} from "react-router-dom";
import ImageLoader from "../../commons/imageLoader/imageLoader";
import Plx from 'react-plx';

class Work extends React.Component {
  static contextType = LoaderContext;
  parallax;
  isLoading = false;
  completeList = {};
  oddProjects = {};
  evenProjects = {};

  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.isLoading,
      completeList: this.completeList,
      evenProjects: this.evenProjects,
      oddProjects: this.oddProjects,
    };
  }

  componentWillMount() {
    this.updateIsLoading(true);
    axios.get(projectsUrl).then(res =>{
      res.data.forEach(async (el, index) => {
        this.saveElement(index, el.id, el.covers['max_808'] || el.covers['404']);
      });

      this.updateIsLoading(false);
      this.setState({
        completeList: this.completeList,
        evenProjects: this.evenProjects,
        oddProjects: this.oddProjects
      });
    });
  }

  updateIsLoading(isLoading = !this.isLoading) {
    this.context.toggleLoader(isLoading);
    this.isLoading = isLoading;
    this.setState({isLoading: this.isLoading,});
  }

  saveElement(index, id, image) {
    this.completeList[id] = image;
    if (index % 2 === 0) {
      this.evenProjects[id] = image;
    } else {
      this.oddProjects[id] = image;
    }
  }

  generateImagesFromObject() {
    const {innerWidth} = window;
    const parallaxSettings = [
      {
        start: 0,
        duration: 20000,
        properties: [
          {
            startValue: innerWidth >= 768 ? 350 : 70,
            endValue: -800,
            property: "translateY"
          },
        ]
      }
    ];

    return (
      <Fragment>
        <div className={'col-6 left-col'}>
          {this.generateParallax(this.oddProjects)}
        </div>
        <div className={'col-6 right-col'}>
          <Plx parallaxData={parallaxSettings}>
            {this.generateParallax(this.evenProjects)}
          </Plx>
        </div>
      </Fragment>
    );
  }

  generateParallax(objectImages) {
    return Object.entries(objectImages).reverse().map(([index, image]) => {
            return (
                  <Link key={index}
                        to={`/project/${index}`}
                        onMouseOver={()=>this.setHighlight(index)}
                        onMouseOut={()=>this.setHighlight('')}
                        >
                    <ImageLoader key={index}
                                attr-id={index}
                                alt={index}
                                src={image}
                                style={{width: '100%'}}/>
                  </Link>);
            })
  }

  setHighlight(val) {
    this.setState({highlight: val});
  }

  render() {
    return (
      <div className="work container">
        <div className="row no-gutters">
          {this.generateImagesFromObject()}
        </div>
      </div>
    );
  }
}

export default Work;
