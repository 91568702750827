
import React from "react";
import "./videoLoader.scss";

import {LoaderContext} from "../../commons/loader/loader";

class VideoLoader extends React.Component {
  static contextType = LoaderContext;

  //initial state: video loaded stage
  state = {
    loaded: false,
  };

  //define our loading and loaded image classes
  static defaultProps = {
    className: "",
    style: {},
    loadingClassName: "loading",
    loadedClassName: "loaded",

  };

  componentWillMount() {
    this.context.toggleLoader(true);
  }

  videoLoaded() {
    this.context.toggleLoader(false);
    this.setState(() => ({ loaded: true }));
  }

  render() {
    let { className, loadedClassName, loadingClassName, ...props } = this.props;

    className = `${className} ${this.state.loaded
      ? loadedClassName
      : loadingClassName}`;

    return <div style={props.styleWrapper}>
                <iframe src={`${props.src}&loop=true&autoplay=true`}
                        title={props.alt}
                        style={{...props.style, backgroundImage: `url(${props.coverImg})`}}
                        onClick={props.onClick}
                        onLoad={this.videoLoaded.bind(this)}
                        className={className}>
                          </iframe>


           </div>
  }
}

export default VideoLoader;
