import React, { useState } from 'react';
import {Link} from "react-router-dom";
import './header.scss';
import { withRouter } from "react-router";

function Header({location, history }) {
  const [isClose, setIsClose] = useState(true);
  const toggleMenu = () => {
    setIsClose(!isClose);
  }

  history.listen((location, action) => {
    setIsClose(true);
  });

  const menu = (
    <ul className={isClose ? "hidden menu side" : 'menu side'}>
      <li className="burger-menu">
        <button className={!isClose ? 'on hamburguer-bt' : 'hamburguer-bt'} onClick={toggleMenu}>
          <div className="hamburguer-bt__stripe hamburguer-bt__stripe__top"></div>
          <div className="hamburguer-bt__stripe hamburguer-bt__stripe__middle"></div>
          <div className="hamburguer-bt__stripe hamburguer-bt__stripe__bottom"></div>
        </button>
      </li>
      <li><Link to="/work">Work</Link></li>
      <li><Link to="/about">About</Link></li>
      <li><Link to="/contact">Contact</Link></li>
    </ul>);
  const menuByLocation = location.pathname !== '/' ? (menu) : ('');

  return (
    <header className="main-header">
      <div className="header-logo">
        { !(location.pathname.includes('project/') || location.pathname ==='/') ?
            (<h1 className="logo"><Link to="/work">FERNANDO RODRIGUEZ.</Link></h1>) : ('')
        }
      </div>
      {
        menuByLocation
      }
    </header>
  );
}

export default withRouter(Header);
