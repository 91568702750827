
import React from "react";
import "./imageLoader.scss";

import {LoaderContext} from "../../commons/loader/loader";

const _loaded = {};

class ImageLoader extends React.Component {
  static contextType = LoaderContext;

  //initial state: image loaded stage
  state = {
    loaded: _loaded[this.props.src]
  };

  componentWillMount() {
    this.context.toggleLoader(true);
    const img = new Image();
    img.src = this.props.src;

    img.onload = () => {
      this.context.toggleLoader(false);
      this.setState(() => ({ loaded: true }));
      _loaded[this.props.src] = true;
    };
  }

  //define our loading and loaded image classes
  static defaultProps = {
    className: "",
    style: {},
    loadingClassName: "img-loading",
    loadedClassName: "img-loaded",

  };

  render() {
    let { className, loadedClassName, loadingClassName, ...props } = this.props;

    className = `${className} ${this.state.loaded
      ? loadedClassName
      : loadingClassName}`;

    return <div style={props.styleWrapper}>
             <img
             alt={props.alt}
             style={props.style}
             src={props.src}
             onClick={props.onClick}
             className={className} />
           </div>
  }
}

export default ImageLoader;
