import React from 'react';
import './about.scss';
import ImageLoader from "../../commons/imageLoader/imageLoader";
import portrait from "../../resources/images/portrait.jpg";

// TODO(quinonez): ESTO CAMBIARA CUANDO SE CONTRATE HOST
// const portrait = 'https://elasticbeanstalk-us-west-2-594287122795.s3-us-west-2.amazonaws.com/resources/portrait.png';
// const portrait = '../../resources/images/portrait.png';

class About extends React.Component {

  portraitImg;

  componentWillMount() {
    const img = document.createElement('img');
    img.src = portrait;
  }

  render() {
    return (
      <div className="about container">
        <div className="row">
          <div className="col-lg-5 offset-lg-1 about-info">
            <p>
              Fernando Rodriguez is Mexican image maker based in New York. Inspired by the complexities of the human mind and behavior, he brings a unique perspective to his craft, using vivid colors, dynamic perspectives, and innovative light sources.
            </p>
            <p>
              Fernando's journey as an artist began in San Francisco, where he collaborated with local artists and showcased his work at various events. However, it was his return to Mexico City in 2016 that truly established him as a creative force to be reckoned with. Surrounded by the thriving fashion industry, he formed collaborations with talented artists from around the world, and honed his skills to create boundary-pushing images. Through his work, he tries to tell stories that connect and resonate with people on a deeper level.
            </p>
            <p>
              His work has been featured  publications such as Harper's Bazaar, Grazia, Schön!, L'Officiel, TUSH, Infringe, and many others. He has also collaborated with brands such as Dior, Louis Vuitton, Adobe, Domestika, PETA, Royal Caribbean, and Capture One.
            </p>
            <p>
              Fernando has been recognized for his work with awards such as the ADC 99th Annual Awards 2020  in the Fashion Photography Category and the ADC 100th Annual Awards 2021 in the same category. The ADC Annual Awards is the oldest continuously running industry award show in the world.
            </p>
            <p>
              Former Capture One Global Ambassador.
            </p>
            <p>
              “The nobodies: nobody’s children, owners of nothing.” Eduardo Galeano
            </p>
            <p className="award">
              AWARDS
            </p>
            <p>
              ADC 99Th Annual awards 2020. <span className="category-award"> Fashion Photography category.</span>
            </p>
          </div>
          {/*
            TODO(ricardoq): Try to make this a component in order to add a load
            effect.
          */}
          <div className="col-lg-5 offset-lg-1">
            <ImageLoader alt="protrait" className="img-fluid" attr-id={this.props.id} src={portrait}/>
          </div>
          {/* <img alt="protrait" className="img-fluid col-lg-5 offset-lg-1" src={portrait}/> */}
        </div>
      </div>
    );
  }
}

export default About;
