import React, { useState } from "react";
import Header from './commons/header/header';
import About from './sections/about/about';
import Contact from './sections/contact/contact';
import Home from './sections/home/home';
import Work from './sections/work/work';
import Project from './sections/project/project';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';
import Loader, { LoaderContext } from './commons/loader/loader';
// import { LoaderContext } from './commons/loader/loader';
import "./App.scss";

function App() {
  const [loader, setLoader] = useState(false);
  const loaderCalls = [];
  // TODO(quinonez) debug this on projects
  // TODO(quinonez) LOADERCALLS VAR IS GETTING RESET
  const toggleLoader = (val) => {
    if (val) {
      loaderCalls.push(val);
      // console.log(loaderCalls.length);
      setLoader(val);
      return;
    }

    if (!loaderCalls.length) {
      setLoader(val);
    }
    loaderCalls.pop();
    // console.log(loaderCalls.length);
  }
  const value = { loader, toggleLoader };

  return (
    <Router>
      <Header/>
      <LoaderContext.Provider value={value}>
        <AnimatedSwitch atEnter={{ opacity: 0 }}
                        atLeave={{ opacity: 0 }}
                        atActive={{ opacity: 1 }}
                        className="switch-wrapper">
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/work">
            <Work />
          </Route>
          <Route path="/project/:id">
            <Project />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </AnimatedSwitch>
        <Loader></Loader>
      </LoaderContext.Provider>
    </Router>
  );
}

export default App;
