import React from "react";
import "./project.scss";
import axios from "axios";
import { withRouter } from "react-router";
import { getProjectUrl } from "../../commons/constants";
import ImageLoader from "../../commons/imageLoader/imageLoader";
import VideoLoader from "../../commons/videoLoader/videoLoader";
import {LoaderContext} from "../../commons/loader/loader";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

class Project extends React.Component {
  static contextType = LoaderContext;

  parallax;
  oddProjects = [];
  evenProjects = [];

  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    this.state = {
      idProject: id,
      projectImgs: [],
      title: "",
      coverImg: '',
    };
  }

  async componentWillMount() {
    this.context.toggleLoader(true);
    const res = await axios.get(getProjectUrl(this.state.idProject));
    const { project = {} } = res.data;
    const projectImgs = project?.modules?.reduce((acc, cur) => {
      if (cur.type === 'image') {
        acc.push({src: cur.sizes.max_1240, type: 'image', alt: cur.alt_text || cur.id});
      }
      if(cur.type === 'video') {
        acc.push({src: cur.src, type: 'video', alt: cur.alt_text});
      }
      return acc;
    }, []);

    this.setState({
      projectImgs: projectImgs,
      title: project.name,
      coverImg: project.covers.original,
    });
    this.context.toggleLoader(false);
  }

  render() {
    return (
      <div className="project">
        <AwesomeSlider mobileTouch={true} bullets={false} style={{height: 'calc(100% - 48px)'}} organicArrows={false}>
          {this.state.projectImgs.map((item, index) => (
            <div key={index} style={{
                height: '100%',
                backgroundColor: 'transparent',
                paddingTop: '1rem'
              }}>
              {item.type === 'image' ? <ImageLoader key={index}
                           src={item.src}
                           alt={index}
                           style={{maxHeight: '100%', maxWidth: '100%'}}
                           styleWrapper={{
                             overflow: 'hidden',
                             height: '100%',
                             display: 'flex',
                             alignItems: 'center'}}/> :
                           <VideoLoader key={index}
                                        src={item.src}
                                        alt={item.alt}
                                        style={{
                                          maxHeight: '100%',
                                          maxWidth: '100%'}}
                                        coverImg={this.state.coverImg}
                                        styleWrapper={{
                                          overflow: 'hidden',
                                          height: '100%',
                                          display: 'flex',
                                          alignItems: 'center'}}/>}
            </div>
          ))}
        </AwesomeSlider>
        <h2>{this.state.title}</h2>
      </div>
    );
  }
}

export default withRouter(Project);
