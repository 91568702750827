import React from 'react';
import {Link} from "react-router-dom";
import './home.scss';
import home from '../../resources/images/home.jpg'
import homeMini from '../../resources/images/home-mini.png'
// TODO(quinonez): RECUERDA QUE TE COBRARAN POR USAR AMAZON DESPUES DE UN ANO, ESTO TIENE QUE CAMBIAR
// TODO(quinonez): ESTO CAMBIARA CUANDO SE CONTRATE HOST
// const home = 'https://elasticbeanstalk-us-west-2-594287122795.s3-us-west-2.amazonaws.com/resources/home.jpg';
// const homeMini = 'https://elasticbeanstalk-us-west-2-594287122795.s3-us-west-2.amazonaws.com/resources/home-mini.png';
// const home = '../../resources/images/home.jpg';
// const homeMini = '../../resources/images/home-mini.png';

class Home extends React.Component {

  // TODO(quinonez): Fix blur fadeIn on load
  // TODO(quinonez): Fix parallax issue
  // TODO(quinonez): Make loading bar to work as a stack
  // TODO(quinonez): Take a look to SEO?
  src = null;
  state = { src: this.src };

  componentDidMount() {
    const imageLoader = new Image();
    imageLoader.src = home;

    imageLoader.onload = () => {
      this.src = home
      this.setState({ src: this.src });
    };
  }

  render() {
    const className = this.state.src ? 'home img-loadded' : 'home img-loading';
    const src = {backgroundImage: `url(${this.state.src ? this.state.src : homeMini})`};
    return (
      <div className={className}
           style={src}>
             { this.state.src ? (<h1>FERNANDO RODRIGUEZ.</h1>) : ('') }
             { this.state.src ? (<Link to="/work">WORK</Link>) : ('') }
      </div>
    );
  }
}

export default Home;
