import React from 'react';
import './loader.scss';

export const LoaderContext = React.createContext({
  loader: false,
  toggleLoader: () => {},
});

class Loader extends React.Component {

  render() {
    return (
      <LoaderContext.Consumer >
        {({loader}) => {
          return (
          <div className="loader-container">
            <div className={`loader ${loader ? 'loading' : ''}`}>
            </div>
          </div>
          )
        }}
      </LoaderContext.Consumer >
    )
  }
}

export default Loader;
