import React, { useState, useEffect } from 'react';
import './contact.scss';
import { userUrlUrl } from "../../commons/constants";
import axios from "axios";

function Contact() {
  const [userUrl, setUserURl] = useState(0);

  useEffect(() => {
    const fetchUrl = async () => {
      setUserURl((await (axios.get(userUrlUrl))).data.url);
    }
    fetchUrl();
  });

  return (
    <div className="contact container">
      <div className="row">
        <div className="col-lg-11 offset-lg-1 contact-info"> 
          <div>
            <label>Email</label>
            <a href="mailto:info@nobodystudio.com">info@nobodystudio.us</a>
          </div>
          <div>
            <label>Links</label>
            <a href={userUrl} target="_blank" rel="noopener noreferrer">
              {(userUrl || '').split('www.')[1]}
            </a>
            <a href="https://www.instagram.com/nobody_studio" target="_blank" rel="noopener noreferrer">
              instagram.com/nobody_studio
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
